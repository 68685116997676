import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getTranslate } from 'react-localize-redux';
import { endWorkflow } from '../../actions/workflows';
import SidebarRight from '../commons/sidebar-right';
import { next, reset } from '../../actions/workflow-item';
import ReduxWrapperHoc from './ReduxWrapperHoc';
import { reduxFormKeys } from '../../dto/workflow';
import { Container, Row, Col, Alert } from 'reactstrap';
class SidebarWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSidebar: false
        }
        this.onClosed = this.onClosed.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        const { sidebarWorkflow } = nextProps;
        this.setState({ showSidebar: !sidebarWorkflow || sidebarWorkflow === undefined ? false : true });
    }
    onClosed() {
        const { sidebarWorkflow: { workflowModel, sessionId }, endWorkflow } = this.props;
        this.setState({ showSidebar: false }, () => {
            endWorkflow({ workflowId: workflowModel, sessionId });
        });
    }
    submitForm(values) {
        const { onSubmit, sidebarWorkflow } = this.props;
        onSubmit(sidebarWorkflow, values);
    }
    render() {
        const { sidebarWorkflow, handleSubmit, workflowData } = this.props;
        const { showSidebar } = this.state;
        if (!sidebarWorkflow) return null;
        const { currentItem: { name }, workflowError } = sidebarWorkflow;
		return (
            <div>
                <SidebarRight onClosed={this.onClosed} open={showSidebar} className='subworkflow-sidebar'>
                {
                    (workflowError && workflowError.hasError) ? (
                        <Container>
                            <Row>
                                <Col>
                                    <Alert style={{ marginTop: 10 }} color="danger">
                                        {workflowError.message}
                                    </Alert>
                                </Col>
                            </Row>
                        </Container>
                    ) : (<ReduxWrapperHoc 
                            onSubmit={this.submitForm.bind(this)}
                            jumptoSubmit={this.submitForm.bind(this)}
                            initialValues={workflowData || {}}
                            workflowData={workflowData}
                            form={reduxFormKeys.sidebar}
                            className='sidebar-workflow-form'
                            isBase={false}
                            workflowForContainer={sidebarWorkflow}
                        />
                    )
                        
                }        
                </SidebarRight>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const sidebarWorkflow = state.getIn(['workflow', 'sidebarWorkflow']);
	const containers = state.getIn(['workflow', 'baseWorkflow', 'currentItem', 'layout', 'containers']);
    const workflowData = sidebarWorkflow ? sidebarWorkflow.get('workflowData') : null;
    return {
        translate: getTranslate(state.get('locale')),
        sidebarWorkflow,
        workflowData,
		updatedVersion: workflowData ? workflowData.get('version') : null,
        companyDetails: state.getIn(['company', 'details'])
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        endWorkflow: (payload) => {
            dispatch(endWorkflow(payload));
        },
        onSubmit: (sidebarWorkflow, values) => {
            dispatch(next(sidebarWorkflow, values));
            dispatch(reset());
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(toJS(SidebarWorkflow));