import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Filters from './index';
import Pagination from '../../../commons/pagination';
import { Row,Col } from 'reactstrap';
import SideFilters from './SideFilters';
import Select from 'react-select';


class FilterWrapperHOC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: ''
        }
    }
    getIsFilterActive(filterConfig) {
        let isActive = true;
        if (filterConfig && typeof filterConfig.active === 'boolean') {
            isActive = filterConfig.active;
        }
        return isActive;
    }
    getFilterConfigFromLayoutItem(layoutItem) {
        let config = null;
        if (layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.filterConfig) {
            config = layoutItem.itemProperties.filterConfig;
        }
        return config;
    }
    getFilterConfigFromWorkflowItem(workflowItem) {
        let config = null;
        const {containerIndex} = this.props;
        if (workflowItem && workflowItem.layoutProperties && workflowItem.layoutProperties.readAllFilterConfig && workflowItem.layoutProperties.readAllFilterConfig[containerIndex] && workflowItem.layoutProperties.readAllFilterConfig[containerIndex].filterConfig) {
            config = workflowItem.layoutProperties.readAllFilterConfig[containerIndex].filterConfig;
        }
        else if (workflowItem && workflowItem.layoutProperties && workflowItem.layoutProperties.readAllFilterConfig && workflowItem.layoutProperties.readAllFilterConfig.filterConfig) {
            config = workflowItem.layoutProperties.readAllFilterConfig.filterConfig;
        }
        return config;
    }
    handleChange(selectedOptions) {
        const { field, onFilterChange,filter, filterIndex } = this.props;
        this.setState({ selectedOptions });
    }
    render() {
        const { useLayoutItem, children, paginationStart, pageSize, pagination, onChangePage, collection, onChangePageSize, 
            tableStyleConfig,filterTemplate, selectedTemplate, sortFieldsConfig, onSortApplied,horizontalViewMode } = this.props;
        const filterConfig = useLayoutItem ? this.getFilterConfigFromLayoutItem(this.props.layoutItem) : this.getFilterConfigFromWorkflowItem(this.props.workflowItem);
        const filters = filterConfig && filterConfig.filters ? filterConfig.filters : [];
        const allFilters = (filterConfig && filterConfig.filterAll) ? true : false;
        const options = [];
        sortFieldsConfig && sortFieldsConfig.map(item=>{
            options.push({label: item.displayText, value: item.sortField})
        })
        const selectedOptions = this.state.selectedOptions;
        if(filterTemplate === 'Template3'){
            return (
                <div>
                {
                    sortFieldsConfig && sortFieldsConfig.length > 0 && <div className='sirt-div d-flex'>
                        <p>Sort by </p> 
                        <Select
                            name='sortFields'
                            value={selectedOptions}
                            onChange={this.handleChange.bind(this)}
                            options={options}
                            clearable={false}
                            isMulti={true}
                            placeholder='Select to sort...'
                            className={'Select-Control ml-1 mr-1'}
                            classNamePrefix={'Select-Control-inner'}
                        />
                        <i onClick={()=> onSortApplied(selectedOptions, 'descending')} className="fa fa-long-arrow-down mt-2 mr-1 btn-link" aria-hidden="true"></i>
                        <i onClick={()=> onSortApplied(selectedOptions, 'ascending')} className="fa fa-long-arrow-up mt-2 btn-link" aria-hidden="true"></i>
                    </div>
                }
                {
                    this.getIsFilterActive(filterConfig) ? <Filters alwaysShow={true}  filterConfig={filterConfig} allFilters={allFilters} filters={filters} fetchAfterFilter={this.props.fetchAfterFilter} filterTemplate={filterTemplate} {...this.props} /> : null
                }
           
                <Row>
                    <Col lg={4} md={4} sm={4}>
                    {
                    this.getIsFilterActive(filterConfig) ? <SideFilters alwaysShow={true}  filterConfig={filterConfig} allFilters={allFilters} filters={filters} fetchAfterFilter={this.props.fetchAfterFilter} filterTemplate={filterTemplate} {...this.props} /> : null
                }
                    </Col>
                    <Col lg={8} md={8} sm={8}>
                    {children}
                    {
                    pagination && collection && !(tableStyleConfig && tableStyleConfig.enablePagination )  &&  <Pagination 
                        start={paginationStart} 
                        hasNextPage={collection ? collection.data.length === pageSize : false}
                        size={pageSize}
                        onChangePage={onChangePage}
                        onChangePageSize={onChangePageSize}
                        totalCount={collection && collection.totalCount ? parseInt(collection.totalCount) : 0}
                    />
                }
                </Col>
                </Row>
               
            </div>
            )
        }
        return (
            <div>
                {
                    sortFieldsConfig && sortFieldsConfig.length > 0 && <div className='sirt-div d-flex'>
                        <p>Sort by </p> 
                        <Select
                            name='sortFields'
                            value={selectedOptions}
                            onChange={this.handleChange.bind(this)}
                            options={options}
                            clearable={false}
                            isMulti={true}
                            placeholder='Select to sort...'
                            className={'Select-Control ml-1 mr-1'}
                            classNamePrefix={'Select-Control-inner'}
                        />
                        <i onClick={()=> onSortApplied(selectedOptions, 'descending')} className="fa fa-long-arrow-down mt-2 mr-1 btn-link" aria-hidden="true"></i>
                        <i onClick={()=> onSortApplied(selectedOptions, 'ascending')} className="fa fa-long-arrow-up mt-2 btn-link" aria-hidden="true"></i>
                    </div>
                }
                {
                    this.getIsFilterActive(filterConfig) ? <Filters filterConfig={filterConfig} allFilters={allFilters} filters={filters} fetchAfterFilter={this.props.fetchAfterFilter} {...this.props} /> : null
                }
                {children}
                {
                  !horizontalViewMode &&  pagination && collection && !(tableStyleConfig && tableStyleConfig.enablePagination )  &&  <Pagination 
                        start={paginationStart} 
                        hasNextPage={collection ? collection.data.length === pageSize : false}
                        size={pageSize}
                        onChangePage={onChangePage}
                        onChangePageSize={onChangePageSize}
                        totalCount={collection && collection.totalCount ? parseInt(collection.totalCount) : 0}
                    />
                }
            </div>
        );
    }
}

FilterWrapperHOC.defaultProps = {
    useLayoutItem: false
}

FilterWrapperHOC.propTypes = {
    useLayoutItem: PropTypes.bool,
};

export default FilterWrapperHOC;