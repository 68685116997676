import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderSelect, genericNormalizer } from './_FORM_FIELDS';
import _ from 'lodash';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { onUIAction } from '../../utils/uiActions';
import TimelineDropdown from './TimelineDropdown';

const normalize = (multiple, selected) => {
  if (!selected) return selected;
  if (multiple) {
    const nativeValues = [];
    selected.map(option => {
      nativeValues.push(option.value);
    });
    return nativeValues;
  } else {
    return selected.value;
  }
};

const Dropdown = props => {
  const {
    classProperty,
    type,
    validations,
    isReadOne,
    isMulti,
    customIcons,
    ...rest
  } = props;
  const {
    layoutItem: {
      itemProperties: {
        field: { properties },
      },
    },
  } = props;
  const { formValues } = props;
  const { itemProperties } = props.layoutItem;
  const {
    label,
    field,
    placeholder,
    dropdownConfig,
    isLayoutItemEditable,
    editableFieldAction,
    contextual_text_color,
  } = itemProperties;
  const multiple = typeof isMulti === 'boolean' ? isMulti : false;
  let { possibleValues } = properties;
  const uia = props.workflow && props.workflow.currentItem.layoutProperties.uiActions;
  let uiActions = null;
  if (Array.isArray(uia)) {
    uia.map((action, aIndex) => {
      if (classProperty === action.triggerActionOn) {
          uiActions = uia;
      }
    });
  }
  let finalOptions = []
  const sortedPossibleValue = dropdownConfig && dropdownConfig.sortDropdown ? possibleValues.sort() : possibleValues
  const showStageTimeline = dropdownConfig && dropdownConfig.showStageTimeline 
  sortedPossibleValue.map(val => {
    const filterField = contextual_text_color && contextual_text_color[val + '_sourceField'];
    let sourceValue = contextual_text_color && contextual_text_color[val + '_sourceValue'];
    let fieldValue = '';
    if (filterField && filterField.indexOf('.') == -1) {
      fieldValue = formValues && formValues[`${filterField}`];
    } else {
      fieldValue = formValues && ModelPropertiesParser(`{{${filterField}}}`, formValues)
    }
    if(filterField && sourceValue) {
      sourceValue = sourceValue.trim();
      fieldValue = fieldValue ? fieldValue.trim() : fieldValue;
      let found = false;
      if(sourceValue.indexOf('|') != -1){
        sourceValue = sourceValue.split('|');
        found = sourceValue.find(element => element == fieldValue);
      } else {
        if(sourceValue.indexOf('!') == 0){
          sourceValue = sourceValue.split('!')
          if(fieldValue !== sourceValue[1]) {
            found = true
          }
        }
        if(fieldValue == sourceValue) {
          found = true
        }
       
      }
      if(found) {
        finalOptions.push(val)
      }
    } else {
      finalOptions.push(val)
    }
  });
  const options = finalOptions.map(val => {
    return {
      value: val,
      label: contextual_text_color && contextual_text_color[val + '_text'] && contextual_text_color[val + '_text'].length ? 
              contextual_text_color[val + '_text'] : val,
      icon: contextual_text_color ? contextual_text_color[val + '_icon'] : null,
    };
  })
  if (isLayoutItemEditable) {
    return (
      <div>
        <Field
          component={renderSelect}
          name={classProperty}
          validate={validations}
          type='select'
          fieldType={type}
          onUIAction={onUIAction}
          uiActions={uiActions}
          dispatchForUiAction={props.dispatch}
          valueKey='value'
          labelKey='label'
          options={options}
          properties={properties}
          customIcons={customIcons}
          renderSelectType={
            dropdownConfig && dropdownConfig.defaultState
              ? dropdownConfig.defaultState.value
              : 'DROPDOWN'
          }
          isLayoutItemEditable={isLayoutItemEditable}
          normalize={normalize.bind(this, multiple)}
          multiple={multiple}
          editableFieldAction={editableFieldAction}
          {...rest}
        />
      </div>
    );
  }

if(showStageTimeline){
  return <TimelineDropdown options={options} {...props}/>
}
 
  return (
    <div>
      {isReadOne ? (
        <PrintText {...props} />
      ) : (
        <Field
          name={classProperty}
          component={renderSelect}
          type='select'
          validate={validations}
          disabled={props.disableLayoutItem || props.disableState}
          fieldType={type}
          onUIAction={onUIAction}
          uiActions={uiActions}
          dispatchForUiAction={props.dispatch}
          valueKey='value'
          labelKey='label'
          options={options}
          customIcons={customIcons}
          properties={properties}
          onDataChange={(v)=> {
            const cEventKeys = window.cEvents && Object.keys(window.cEvents);
            const fValues = props.formValues;
            _.set(fValues,classProperty,v)
            Array.isArray(cEventKeys) && cEventKeys.map(k => {
              k.startsWith(classProperty) &&
                window.cEvents[k](fValues);
            });
          }}
          {...rest}
          renderSelectType={
            dropdownConfig && dropdownConfig.defaultState
              ? dropdownConfig.defaultState.value
              : 'DROPDOWN'
          }
          normalize={normalize.bind(this, multiple)}
          multiple={multiple}
        />
      )}
    </div>
  );
};

export default Dropdown;
