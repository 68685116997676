import React, {useState} from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, CardImg } from 'reactstrap';
import TextTruncate from 'react-text-truncate';
import { Button } from 'reactstrap';
import CommonFooterActions from './CommonFooterActions';
import config from '../../../config';
import TextStyleResolver from '../../elements/TextStyleResolver/index';
import { filter } from 'lodash';

const EnchancedImageCard = (props) => {

    const [isHovering, setIsHovering] = useState(false);
    
    const { title, subtitle, description, cardConfig, image, imageCardStyle,triggerAction,data,workflow } = props;
    const noImageURL = cardConfig.config && cardConfig.config.noImageURL ;
    const cardImage = image ? image.startsWith('http') ? image : config.assetsBasePath + image: noImageURL ? noImageURL:'https://assets.business.codeninja.ai/instagram/undraw_No_data_re_kwbl-202307311036.png';
     const backgroundImage_Color = cardConfig.config && cardConfig.config.backgroundImage_Color ;
   
    const backgroundImage = cardConfig.config && cardConfig.config.backgroundImage && cardConfig.config.backgroundImage.path
    const backgroundImagUrlComplete = `url(${config.assetsBasePath}${backgroundImage})`
    const imageCardLayout= cardConfig.config && cardConfig.config.imageCardLayout ? cardConfig.config.imageCardLayout.value :null;
    const {authTemplateBackgroundColor,cardDescription,cardImagePadding,cardImageHeight,cardImageWidth,cardSubtitle,cardTitle,imageRadius,cardSize,borderRadius,cardTextColor,disableHoverEffect} = cardConfig && cardConfig.config;    
    const borderRadiusCard = borderRadius ? parseInt(borderRadius): 10 

    const cardImageRadius = imageRadius ? parseInt(imageRadius): 10 

    const imagPadding = cardImagePadding ? parseInt(cardImagePadding): 10 ;
    const imageHeight = cardImageHeight ? `${cardImageHeight}%`: '100%' ;
    const imageWidth = cardImageWidth ? `${cardImageWidth}%`: '100%' ;
    const inlineActions = props.inlineActions;
    const filteredActions = inlineActions.filter((action) => action.actionProperties.systemGenerated !== true && action.actionProperties.inlineActionPlacement === 'INLINE')
    let useTouch = false;
    if(filteredActions && filteredActions.length == 1 && filteredActions[0].actionProperties.actionDisabled) {
        useTouch = true;
    }
    let width = 250;
        if( cardSize >= '6'){
            width= 400
        }
    if(imageCardLayout === 'horizontal'){
        return <Card style={{borderRadius:borderRadiusCard,color:cardTextColor?cardTextColor:"#000", backgroundColor:backgroundImage_Color? backgroundImage_Color:'#fff',backgroundImage:backgroundImage? backgroundImagUrlComplete:'#fff',marginLeft:10,marginRight:10,flexDirection:'row'}}>
     <div  style={{flexDirection:'column'}}>
       <div>
        <CardImg  style={{borderRadius: cardImageRadius,padding:imagPadding,width:width}}   src={cardImage} alt="Card image cap" />
        </div>
        <CommonFooterActions isImageCard = {true} {...props} />
        </div>
        <div>
        <CardBody>
            <CardTitle tag='h5'>
                <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
            </CardTitle>
            <CardSubtitle>
                <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
            </CardSubtitle>
            <CardText tag='div' className='mt-2' title={description}>
                {
                    typeof description === 'string' ? <TextTruncate
                        line={3}
                        truncateText="…"
                        text={typeof description === 'string' ? description : ''}
                    /> : <div>{description}</div>
                }
            </CardText>
        </CardBody>
  
        </div>
    </Card>
    }
    return <Card onMouseOver = {() => {setIsHovering(true)}} onMouseOut = {(e) => { if(e.target.tagName !== 'IMG') setIsHovering(false)}}
        style={{borderRadius:borderRadiusCard,color:cardTextColor?cardTextColor:"#000", backgroundColor:backgroundImage_Color? backgroundImage_Color:'#fff',backgroundImage:backgroundImage_Color? backgroundImage_Color:'#fff',marginLeft:10,marginRight:10,cursor:useTouch?'pointer':'auto'}}>
        {useTouch && isHovering?(
        <span style={{width:'92%', height:'70%', position:'absolute', background:'#333333cc', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Button onClick={() => {
                triggerAction(filteredActions[0], data.id, workflow)
            }}>{filteredActions[0].actionProperties.elementText}</Button>
        </span>):null}
        <CardImg  style={{borderRadius: cardImageRadius,padding:imagPadding}}  width="100%" src={cardImage} alt="Card image cap" />
        <CardBody>
            <CardTitle tag='h5'>
                <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
            </CardTitle>
            <CardSubtitle>
                <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
            </CardSubtitle>
            <CardText tag='div' className='mt-2' title={description}>
                {
                    typeof description === 'string' ? <TextTruncate
                        line={3}
                        truncateText="…"
                        text={typeof description === 'string' ? description : ''}
                    /> : <div>{description}</div>
                }
            </CardText>
        </CardBody>
        <CommonFooterActions isImageCard = {true} {...props} />
    </Card>
}

export default EnchancedImageCard;

