import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FormGroup, Input, Label, FormFeedback, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Iterable } from 'immutable';
import { Button } from 'reactstrap';
import { getMatchedAction } from '../workflow/action/workflowActionsUtils';
import resolvePath from '../../utils/resolvePath';
import { array } from 'prop-types';
import RenderIcon from '../commons/RenderIcon';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { Progress } from 'antd';
import Select, { components } from "react-select";
import { Switch } from 'antd';

export const renderField = props => {
  const {
    translate,
    input,
    label,
    info,
    useLabelAsPlaceholder,
    type,
    placeholder,
    value,
    min,
    disabled,
    max,
    step,
    layoutItem,
    isCustom,
    toggleisCustom,
    othersAsOption,
    numberConfig,
    workflow: { currentItem: { layoutProperties}},
    meta: { touched, error },
  } = props;
  const hasError = touched && error;
  const [currentValue, setCurrentValue] = input.value
    ? useState(input.value)
    : useState('');
  useEffect(()=>{
    setCurrentValue(input.value)
  }, [input.value])
  const { properties } = layoutItem.itemProperties.field;
  const { isLayoutItemMasked } = layoutItem.itemProperties;
  const labelStyle = layoutProperties && layoutProperties.labelStyle;
  const eleStyle = props.eleStyle;
  const minLength =
    layoutItem &&
    layoutItem.itemProperties &&
    layoutItem.itemProperties.minLength
      ? layoutItem.itemProperties.minLength
      : null;
  const classProperty = input.name;
  let placeholderToShow = useLabelAsPlaceholder
    ? label.text
    : placeholder.show
    ? placeholder.text
    : null;
  let translatedLabel = label.text;
  //HACK HACK HACK! Need to figure out how to show the details when we have multilevel selection
  if (classProperty.indexOf('.') != -1 && disabled) {
    placeholderToShow = ModelPropertiesParser(
      '{{' + classProperty + '}}',
      props.formValues
    );
  }
  if (label.languageKey) {
    translatedLabel = translate(
      `label.${label.languageKey.toLowerCase().replace(/ /g, '_')}`
    );
    translatedLabel = translatedLabel.startsWith('Missing translation')
      ? label.text
      : translatedLabel;
  }
  const fieldID = layoutItem.itemProperties && layoutItem.itemProperties.field && layoutItem.itemProperties.field.id;

  
  const enableProgressView = numberConfig && numberConfig.enableProgressView;
  const progressBarHeading = numberConfig && numberConfig.progressBarHeading;
  const progressBarUnitName = numberConfig && numberConfig.progressBarUnitName;
  const minimumProgressValue = numberConfig && numberConfig.minimumProgressValue;
  const maximumProgressValue = numberConfig && numberConfig.maximumProgressValue;
  const minProgressValueInt = minimumProgressValue ? parseInt(minimumProgressValue) : null
  const maxProgressValueInt = maximumProgressValue ? parseInt(maximumProgressValue) : null
  const percentageProgressBar =(currentValue / maxProgressValueInt)*100

  return (
    <FormGroup>
      {enableProgressView && 
      <div className='numberProgressBar'>
        <div className='numberProgressBarLabelDiv'>
      <Label>{progressBarHeading}</Label> 
      <p >{minProgressValueInt} {progressBarUnitName} of {maxProgressValueInt} {progressBarUnitName} Avalible </p>
      </div>
        <Progress percent={percentageProgressBar} showInfo={true}  />
      </div>
      }
      {label.show ? (
        <Label style={labelStyle?{textTransform:labelStyle}:{}} for={input.name}>
          {translatedLabel}
          <sup>{properties && properties.required || minLength ? '*' : ''}</sup>
          {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
            {info.text}
          </UncontrolledTooltip>
          </>
           }
          {othersAsOption && <> <i onClick={()  => toggleisCustom()} className="fa fa-edit ml-3 info-icon-Li" aria-hidden="true" id={`otherAsOption2`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`otherAsOption2`}>
            {isCustom ? 'Select from the List':'Add Manually' }
          </UncontrolledTooltip>
          </>
           }
        </Label>
      ) : (
        ''
      )}
      <Input
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        placeholder={placeholderToShow}
        valid={hasError ? false : null}
        type={!isLayoutItemMasked ? type : `password`}
        {...input}
        value={currentValue}
        onChange={ev => {
          const v = ev.target.value;
          // Preventing onChange to be triggred multiple times
          if(typeof v === 'string' && v.length === 1){
            input.onChange(ev)
          }
          setCurrentValue(v);
        }}
        onBlur ={
          (e)=>{
          // OnChange After Blur  
          input.onChange(e)
          // This is only for UI Action 
          if (classProperty.indexOf('.') == -1) {
            const formValues = props.formValues;
            formValues[classProperty] = currentValue;
            setTimeout(()=>{
              props._onBlur && props._onBlur(formValues, input.name)
            }, 0)
            
          }
          }
        }
        style={eleStyle}
      />
        {enableProgressView &&  currentValue > maxProgressValueInt? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          Value Entered should be less than {maxProgressValueInt}
        </FormFeedback>
      ) : (
        ''
      )}
       {enableProgressView &&  minProgressValueInt > currentValue? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          Value Entered should be greater than {minProgressValueInt}
        </FormFeedback>
      ) : (
        ''
      )}
      {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
    </FormGroup>
  );
};

export const renderMultipleCheckBox = ({
  input,
  label,
  type,
  placeholder,
  value,
  options,
  valueKey,
  labelKey,
  layoutItem,
  meta: { touched, error },
  __form__values,
}) => {
  const hasError = touched && error;
  const { properties } = layoutItem.itemProperties.field;
  const minLength =
    layoutItem &&
    layoutItem.itemProperties &&
    layoutItem.itemProperties.minLength
      ? layoutItem.itemProperties.minLength
      : null;
  let selectedValues = resolvePath(input.name, __form__values);
  if (!selectedValues) {
    selectedValues = [];
  }
  return (
    <FormGroup check className='multi-checkbox-form'>
      {options &&
        options.map((option, index) => {
          return (
            <div key={`${input.name}_${index}`}>
              <Label check>
                <Input
                  onChange={event => {
                    event.target.checked && selectedValues.push(option);
                    if (!event.target.checked) {
                      let existingIndex = -1;
                      Array.isArray(selectedValues) &&
                        selectedValues.map((sv, i) => {
                          if (sv.id === option.id) {
                            existingIndex = i;
                          }
                        });
                      selectedValues.splice(existingIndex, 1);
                    }
                    input.onChange(selectedValues);
                  }}
                  valid={hasError ? false : null}
                  type='checkbox'
                  name={input.name}
                />{' '}
                {option[labelKey].indexOf('#|#') == -1 ? (
                  option[labelKey]
                ) : (
                  <div>
                    <span style={{ float: 'left' }}>
                      {option[labelKey].substring(
                        0,
                        option[labelKey].indexOf('#|#')
                      )}
                    </span>
                    <span style={{ float: 'right' }}>
                      {option[labelKey].substring(
                        option[labelKey].indexOf('#|#') + 3
                      )}
                    </span>
                  </div>
                )}
                <sup>{properties.required || minLength ? '*' : ''}</sup>
              </Label>
            </div>
          );
        })}

      {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
    </FormGroup>
  );
};

export const renderSingleCheckBox = ({
  input,
  label,
  type,
  placeholder,
  defaultvalue,
  disabled,
  value,
  layoutItem,
  meta: { touched, error },
  onUIAction,
  dispatchManual,
  formValues,
  uiAction,
  change,
  displayAs,
  disableLayoutItem,
  useActualClassProperty
}) => {
  const hasError = touched && error;
  const { properties } = layoutItem.itemProperties.field;
  const minLength =
    layoutItem &&
    layoutItem.itemProperties &&
    layoutItem.itemProperties.minLength
      ? layoutItem.itemProperties.minLength
      : null;

  if(input.value === "") {
    input.onChange(false);
  }

  if(displayAs) {
    return <FormGroup check>
      <Label check className={'label_toggle'}>
      <span className='toggle-label'>{label.text}        <sup>{properties.required || minLength ? '*' : ''}</sup></span>
      <Switch defaultValue={input.value} 
      checkedChildren="Yes" unCheckedChildren="No"
      disabled={disableLayoutItem}
      onChange={value => {
            input.onChange(value);
            let actualValues = formValues;
            actualValues[input.name] = value;
            uiAction &&
              onUIAction(actualValues, dispatchManual, uiAction, change);
          }} />{' '}
     

          </Label>
      {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
    </FormGroup>
  }
  return (
    <FormGroup check>
      <Label check>
        <Input
          name={input.name}
          checked={input.value}
          valid={hasError ? false : null}
          type={type}
          disabled={disableLayoutItem ?disableLayoutItem :input.value ? false : disabled}
          defaultChecked={defaultvalue}
          onChange={value => {
            input.onChange(value.target.checked);
            let actualValues = formValues;
            actualValues[input.name] = value.target.checked;
            uiAction &&
              //value.target.checked &&
              onUIAction(actualValues, dispatchManual, uiAction, change);
          }}
        />{' '}
        {label.text}
        <sup>{properties.required || minLength ? '*' : ''}</sup>
      </Label>
      {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
    </FormGroup>
  );
};

export const renderErrorMessage = ({ meta: { touched, error } }) => {
  const hasError = touched && error;
  if (!hasError) return null;
  return (
    <FormFeedback className='animated fadeIn d-block'>
      <i className='fa fa-exclamation-triangle' aria-hidden='true'></i> {error}
    </FormFeedback>
  );
};

export const renderSingleRadio = ({
  input,
  _label,
  type,
  layoutItem,
  meta: { touched, error },
}) => {
  return (
    <FormGroup check>
      <Label check>
        <Input {...input} type='radio' /> {_label}
      </Label>
    </FormGroup>
  );
};

export const renderMultiChoiceRadios = ({
  input,
  label,
  possibleValues,
  classProperty,
  placeholder,
  value,
  layoutItem,
  customIcons,
  change,
  meta: { touched, error },
}) => {
  const hasError = touched && error;
  let { name, type, onChange, ...rest } = input;
  type = 'radio';
  name = `multichoice_layoutitem_${layoutItem.id}_${classProperty}`;
  const rdoChanged = event => {
    change(classProperty, event.target.value);
  };
  return (
    <FormGroup>
      <Row>
        {possibleValues.map((_value, index) => {
          return (
            <Col sm='12' md='4' key={index}>
              <FormGroup check key={index} className='mr-3'>
                <Label check>
                  <Input
                    {...rest}
                    onChange={rdoChanged}
                    value={_value}
                    type={type}
                    name={name}
                  />{' '}
                  {_value}
                </Label>
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col>
          {hasError ? (
            <FormFeedback className='animated fadeIn d-block'>
              <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
              {error}
            </FormFeedback>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </FormGroup>
  );
};

export const renderSelect = ({
  input,
  optionRenderer,
  valueRenderer,
  label,
  options,
  properties,
  placeholder,
  isLoading,
  valueKey,
  renderSelectType,
  editableFieldAction,
  customIcons,
  layoutItem,
  info,
  workflow,
  triggerAction,
  useLabelAsPlaceholder,
  labelKey,
  multiple,
  isCreatable,
  disabled,
  simpleValue,
  showAsRadioButton,
  classProperty,
  optionDisplayType,
  meta: { touched, error },
  change,
  customModelSelector,
  othersAsOption,
  toggleisCustom,
  isCustom,
  translate,
  formValues,
  dispatchForUiAction,
  onUIAction,
  uiActions,
  workflowData,
  layoutItem:{itemProperties:{ enableCustomDropdown,customDropdwonTextRow1Text1,customDropdwonTextRow1Text2,customDropdwonTextRow1Text3,customDropdwonTextRow2Text1,customDropdwonTextRow2Text2,customDropdwonTextRow2Text3}}
  ,workflow: { currentItem: { layoutProperties}},
  eleStyle
}) => {
  const SelectTagType = isCreatable ? Select.Creatable : Select;

  /* FIXIT: Below check for ImmutableJS should not be done UI Component but I am helpless */
  let value = Iterable.isIterable(input.value)
    ? input.value.toJS()
    : input.value;
  if(input.name.includes('[') && input.name.includes(']') && typeof value === "object" && !Array.isArray(value)){
    value['value'] = value.id;
    value['label'] = ModelPropertiesParser(customModelSelector, value);
  } else if(input.name.includes('[') && input.name.includes(']') && typeof value === "object" && Array.isArray(value)){
    value.map((val, index) => {
      value[index]['value'] = value[index].id;
      value[index]['label'] = ModelPropertiesParser(customModelSelector, value[index]);
    })
  }



  if(multiple){
    var newValue = value && value.map((val,index) => ({ 
      label: val, 
      value: val 
    }));
  }
  


  const labelStyle = layoutProperties && layoutProperties.labelStyle
  const minLength =
    layoutItem &&
    layoutItem.itemProperties &&
    layoutItem.itemProperties.minLength
      ? layoutItem.itemProperties.minLength
      : null;
  const defaultvalue =
    layoutItem &&
    layoutItem.itemProperties &&
    layoutItem.itemProperties.countriesConfig &&
    layoutItem.itemProperties.countriesConfig.defaultCountry
      ? layoutItem.itemProperties.countriesConfig.defaultCountry.value
      : '';
  const hasError = touched && error;
  const fieldID = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.field && layoutItem.itemProperties.field.id;
  const menuContainerStyle = {
    zIndex: 99,
  };
  const Option = (props) => {
    return (
      <components.Option {...props}>
       <Col className='customDropdownMainDiv'>
        <Row className='customDropdownSubDiv1'>
      { customDropdwonTextRow1Text1 &&   <Col className='customDropdwonTextRow1Text1'>
          {ModelPropertiesParser(customDropdwonTextRow1Text1,props.data)}
          </Col>}
       { customDropdwonTextRow1Text2 &&  <Col className='customDropdwonTextRow1Text2'>
          {ModelPropertiesParser(customDropdwonTextRow1Text2,props.data)}
            </Col>}
        {  customDropdwonTextRow1Text3 &&  <Col className='customDropdwonTextRow1Text3'>
            {ModelPropertiesParser(customDropdwonTextRow1Text3,props.data)}
            </Col>}
        </Row>
        <Row className='customDropdownSubDiv2'>
       {customDropdwonTextRow2Text1 && <Col className='customDropdwonTextRow2Text1'>
        {ModelPropertiesParser(customDropdwonTextRow2Text1,props.data)}
        </Col>}
    { customDropdwonTextRow2Text2 &&   <Col className='customDropdwonTextRow2Text2'>
        {ModelPropertiesParser(customDropdwonTextRow2Text2,props.data)}
      </Col>}
      { customDropdwonTextRow2Text3 &&     <Col className='customDropdwonTextRow2Text3'>
          {ModelPropertiesParser(customDropdwonTextRow2Text3,props.data)}
          </Col>}
        </Row>
       </Col>
      </components.Option>
    );
  };
  let activeIndex = 0;
  const workflowName = workflow.currentItem.name;
  let translatedLabel = label.text;
  if (label.languageKey) {
    translatedLabel = translate(
      `label.${label.languageKey.toLowerCase().replace(/ /g, '_')}`
    );
    translatedLabel = translatedLabel.startsWith('Missing translation')
      ? label.text
      : translatedLabel;
  }
  options &&
    options.map((val, index) => {
      activeIndex = workflowName.indexOf(val.label) > -1 ? index : -1;
    });

  const onValueChange = (index) => {
    change(input.name, options[index]);
    }

  const valueChange = index => {
    activeIndex = index;
    if (
      editableFieldAction &&
      editableFieldAction.isTextualActionTriggerEnabled
    ) {
      const action = getMatchedAction(
        workflow.currentItem.inlineAction,
        editableFieldAction.textualActionTriggerCode
      );
      triggerAction(action, workflow.workflowData.id, workflow);
    }
    change(input.name, options[activeIndex].value);
  };
  if (renderSelectType !== 'BUTTONS' && !value && defaultvalue) {
    change(input.name, defaultvalue);
  }
  if (renderSelectType === 'BUTTONS' || optionDisplayType === 'showAsButtonGroup')  {
    let buttonRef = [];
    return (
      <div className={`select-buttons form-group`}>
        {label.show ? (
          <Label style={labelStyle?{textTransform:labelStyle}:{}} for={input.name}>
            {label.text}
            <sup>{properties.required || minLength ? '*' : ''}</sup>
            {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
            {info.text}
          </UncontrolledTooltip>
          </>
           }
          </Label>
        ) : (
          ''
        )}
        <ul className='nav nav-pills'>
          {options.map((item, index) => {
            return (
              <li className='nav-item' key={index}>
                <Button
                  color='primary'
                  style={{marginRight:10}}
                  outline 
                  className={`nav-link ${activeIndex == index ? 'active' : item.label == value ? 'active': ''}`}
                  ref={r => (buttonRef[index] = r)}
                  onClick={() => {
                    for (let i = 0; i < buttonRef.length; i++) {
                      let domNode = ReactDOM.findDOMNode(buttonRef[i]);
                      domNode.className = domNode.className.replace(
                        'active',
                        ''
                      );
                      if (i == index) domNode.className += ' active';
                    }
                    valueChange(index);
                  }}
                >
                  {item.icon ? (
                    <RenderIcon customIcons={customIcons} config={item.icon} />
                  ) : null}
                  <span className='text'>{item.label}</span>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  if(showAsRadioButton || optionDisplayType === 'showAsRadioButton'){
  const name = `layoutitem_${label.text}`;
    return <div>
      {label.show ? (
        <Label for={input.name} style={labelStyle?{textTransform:labelStyle}:{}}>
          {label.text}
          <sup>
            {(properties && properties.required) || minLength ? '*' : ''}
          </sup>
          {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
            {info.text}
          </UncontrolledTooltip>
          </>
           }
        </Label>
      ) : (
        ''
      )}
      <div>
      {options.map((option, index) => {
          return (
            <FormGroup style={{padding:5,paddingVertical:10,borderColor:"#000",borderWidth:1,marginBottom:5}} check>
            <Label check >
              <Input type='radio' value={value || defaultvalue}  
               onChange={() =>{
                onValueChange(index)
              } }  
              name={name} style={eleStyle}/> {option.label}
            </Label>
          </FormGroup>
          );
        })}
        </div>
          {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
        </div>
  }
  return (
    <FormGroup>
      {label.show ? (
        <Label for={input.name} style={labelStyle?{textTransform:labelStyle}:{}}>
          {translatedLabel}
          <sup>
            {(properties && properties.required) || minLength ? '*' : ''}
          </sup>
          {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
            {info.text}
          </UncontrolledTooltip>
          </>
           }
            {othersAsOption && <> <i onClick={()  => toggleisCustom()} className="fa fa-edit ml-3 info-icon-Li" aria-hidden="true" id={`otherAsOption`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`otherAsOption`}>
            {isCustom ? 'Select from the List':'Add Manually' }
          </UncontrolledTooltip>
          </>
           }
        </Label>
      ) : (
        ''
      )}
   <SelectTagType
       value={
        multiple ? newValue :
        options && options.filter(function(option) {
        return option.value === value;
      })}
        isMulti={multiple}
        onChange={input.onChange}
        onBlur={onUIAction ? ()=>{
          onUIAction(
            formValues,
            dispatchForUiAction,
            uiActions,
            change,
            input.name
          );
        }:()=>{}}
        options={options}
        valueKey={valueKey}
        labelKey={labelKey}
        isLoading={isLoading}
        placeholder={
          useLabelAsPlaceholder
            ? label.text
            : placeholder.show
            ? placeholder.text
            : 'Please select...'
        }
        optionRenderer={optionRenderer}
        valueRenderer={valueRenderer}
        menuContainerStyle={menuContainerStyle}
        isDisabled={disabled ? true : false}
        className={'Select-Control'}
        classNamePrefix={'Select-Control-inner'}
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...eleStyle
          }),
        }}
        simpleValue={simpleValue ? true : false}
        isClearable={true}
      />
      {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
    </FormGroup>
  );
};


export const renderSelectAnotherType= ({
  input,
  optionRenderer,
  valueRenderer,
  label,
  options,
  properties,
  placeholder,
  isLoading,
  valueKey,
  renderSelectType,
  editableFieldAction,
  customIcons,
  layoutItem,
  info,
  workflow,
  triggerAction,
  useLabelAsPlaceholder,
  labelKey,
  multiple,
  isCreatable,
  disabled,
  simpleValue,
  showAsRadioButton,
  classProperty,
  optionDisplayType,
  meta: { touched, error },
  change,
  customModelSelector,
  othersAsOption,
  toggleisCustom,
  isCustom,
  translate,
  formValues,
  dispatchForUiAction,
  onUIAction,
  uiActions,
  workflowData,
  layoutItem:{itemProperties:{ enableCustomDropdown,autoSelectFirstOption,customDropdwonTextRow1Text1,customDropdwonTextRow1Text2,customDropdwonTextRow1Text3,customDropdwonTextRow2Text1,customDropdwonTextRow2Text2,customDropdwonTextRow2Text3}}
  ,workflow: { currentItem: { layoutProperties}}
}) => {
  const SelectTagType = isCreatable ? Select.Creatable : Select;

  /* FIXIT: Below check for ImmutableJS should not be done UI Component but I am helpless */
  let value = Iterable.isIterable(input.value)
    ? input.value.toJS()
    : input.value;
  if(input.name.includes('[') && input.name.includes(']') && typeof value === "object" && !Array.isArray(value)){
    value['value'] = value.id;
    value['label'] = ModelPropertiesParser(customModelSelector, value);
  } else if(input.name.includes('[') && input.name.includes(']') && typeof value === "object" && Array.isArray(value)){
    value.map((val, index) => {
      value[index]['value'] = value[index].id;
      value[index]['label'] = ModelPropertiesParser(customModelSelector, value[index]);
    })
  }
  const labelStyle = layoutProperties && layoutProperties.labelStyle
  const minLength =
    layoutItem &&
    layoutItem.itemProperties &&
    layoutItem.itemProperties.minLength
      ? layoutItem.itemProperties.minLength
      : null;
  const defaultvalue =
    layoutItem &&
    layoutItem.itemProperties &&
    layoutItem.itemProperties.countriesConfig &&
    layoutItem.itemProperties.countriesConfig.defaultCountry
      ? layoutItem.itemProperties.countriesConfig.defaultCountry.value
      : '';
  const hasError = touched && error;
  const fieldID = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.field && layoutItem.itemProperties.field.id;
  const menuContainerStyle = {
    zIndex: 99,
  };

  
  const Option = (props) => {
    return (
      <components.Option {...props}>
       <Col className='customDropdownMainDiv'>
        <Row className='customDropdownSubDiv1'>
      { customDropdwonTextRow1Text1 &&   <Col className='customDropdwonTextRow1Text1'>
          {ModelPropertiesParser(customDropdwonTextRow1Text1,props.data)}
          </Col>}
       { customDropdwonTextRow1Text2 &&  <Col className='customDropdwonTextRow1Text2'>
          {ModelPropertiesParser(customDropdwonTextRow1Text2,props.data)}
            </Col>}
        {  customDropdwonTextRow1Text3 &&  <Col className='customDropdwonTextRow1Text3'>
            {ModelPropertiesParser(customDropdwonTextRow1Text3,props.data)}
            </Col>}
        </Row>
        <Row className='customDropdownSubDiv2'>
       {customDropdwonTextRow2Text1 && <Col className='customDropdwonTextRow2Text1'>
        {ModelPropertiesParser(customDropdwonTextRow2Text1,props.data)}
        </Col>}
    { customDropdwonTextRow2Text2 &&   <Col className='customDropdwonTextRow2Text2'>
        {ModelPropertiesParser(customDropdwonTextRow2Text2,props.data)}
      </Col>}
      { customDropdwonTextRow2Text3 &&     <Col className='customDropdwonTextRow2Text3'>
          {ModelPropertiesParser(customDropdwonTextRow2Text3,props.data)}
          </Col>}
        </Row>
       </Col>
      </components.Option>
    );
  };
 useEffect(() => {
  if(autoSelectFirstOption && options.length > 0){
    let tempOptions=JSON.stringify(options[0])
    if(tempOptions !== JSON.stringify(input.value) || !input.value){
      input.onChange(options[0]);
    }
  }
 }, [options])
 
  let activeIndex = 0;
  const workflowName = workflow.currentItem.name;
  let translatedLabel = label.text;
  if (label.languageKey) {
    translatedLabel = translate(
      `label.${label.languageKey.toLowerCase().replace(/ /g, '_')}`
    );
    translatedLabel = translatedLabel.startsWith('Missing translation')
      ? label.text
      : translatedLabel;
  }
  options &&
    options.map((val, index) => {
      activeIndex = workflowName.indexOf(val.label) > -1 ? index : -1;
    });

  const onValueChange = (index) => {
    change(input.name, options[index]);
    }

  const valueChange = index => {
    activeIndex = index;
    if (
      editableFieldAction &&
      editableFieldAction.isTextualActionTriggerEnabled
    ) {
      const action = getMatchedAction(
        workflow.currentItem.inlineAction,
        editableFieldAction.textualActionTriggerCode
      );
      triggerAction(action, workflow.workflowData.id, workflow);
    }
    change(input.name, options[activeIndex].value);
  };
  if (renderSelectType !== 'BUTTONS' && !value && defaultvalue) {
    change(input.name, defaultvalue);
  }
  if (renderSelectType === 'BUTTONS' || optionDisplayType === 'showAsButtonGroup')  {
    let buttonRef = [];
    return (
      <div className={`select-buttons form-group`}>
        {label.show ? (
          <Label style={labelStyle?{textTransform:labelStyle}:{}} for={input.name}>
            {label.text}
            <sup>{properties.required || minLength ? '*' : ''}</sup>
            {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
            {info.text}
          </UncontrolledTooltip>
          </>
           }
          </Label>
        ) : (
          ''
        )}
        <ul className='nav nav-pills'>
          {options.map((item, index) => {
            return (
              <li className='nav-item' key={index}>
                <Button
                  color='primary'
                  style={{marginRight:10}}
                  outline 
                  className={`nav-link ${activeIndex == index ? 'active' : item.label == value ? 'active': ''}`}
                  ref={r => (buttonRef[index] = r)}
                  onClick={() => {
                    for (let i = 0; i < buttonRef.length; i++) {
                      let domNode = ReactDOM.findDOMNode(buttonRef[i]);
                      domNode.className = domNode.className.replace(
                        'active',
                        ''
                      );
                      if (i == index) domNode.className += ' active';
                    }
                    valueChange(index);
                  }}
                >
                  {item.icon ? (
                    <RenderIcon customIcons={customIcons} config={item.icon} />
                  ) : null}
                  <span className='text'>{item.label}</span>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  if(showAsRadioButton || optionDisplayType === 'showAsRadioButton'){
  const name = `layoutitem_${label.text}`;
    return <div>
      {label.show ? (
        <Label for={input.name} style={labelStyle?{textTransform:labelStyle}:{}}>
          {label.text}
          <sup>
            {(properties && properties.required) || minLength ? '*' : ''}
          </sup>
          {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
            {info.text}
          </UncontrolledTooltip>
          </>
           }
        </Label>
      ) : (
        ''
      )}
      <div>
      {options.map((option, index) => {
          return (
            <FormGroup style={{padding:5,paddingVertical:10,borderColor:"#000",borderWidth:1,marginBottom:5}} check>
            <Label check >
              <Input type='radio' value={value || defaultvalue}  
               onChange={() =>{
                onValueChange(index)
              } }  
              name={name} /> {option.label}
            </Label>
          </FormGroup>
          );
        })}
        </div>
          {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
        </div>
  }
  return (
    <FormGroup>
      {label.show ? (
        <Label for={input.name} style={labelStyle?{textTransform:labelStyle}:{}}>
          {translatedLabel}
          <sup>
            {(properties && properties.required) || minLength ? '*' : ''}
          </sup>
          {info && info.show && info.text && <> <i className="fa fa-info-circle ml-3 info-icon-Li" aria-hidden="true" id={`li_${fieldID}`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`li_${fieldID}`}>
            {info.text}
          </UncontrolledTooltip>
          </>
           }
            {othersAsOption && <> <i onClick={()  => toggleisCustom()} className="fa fa-edit ml-3 info-icon-Li" aria-hidden="true" id={`otherAsOption`}></i>
            <UncontrolledTooltip delay={100} className={'infoToolTip'} placement='right' target={`otherAsOption`}>
            {isCustom ? 'Select from the List':'Add Manually' }
          </UncontrolledTooltip>
          </>
           }
        </Label>
      ) : (
        ''
      )}
   <SelectTagType
       value={value || defaultvalue}
        isMulti={multiple}
         components={enableCustomDropdown? {Option}: null}
        onChange={input.onChange}
        onBlur={onUIAction ? ()=>{
          onUIAction(
            formValues,
            dispatchForUiAction,
            uiActions,
            change,
            input.name
          );
        }:()=>{}}
        options={options}
        valueKey={valueKey}
        labelKey={labelKey}
        isLoading={isLoading}
        placeholder={
          useLabelAsPlaceholder
            ? label.text
            : placeholder.show
            ? placeholder.text
            : 'Please select...'
        }
        optionRenderer={optionRenderer}
        valueRenderer={valueRenderer}
        menuContainerStyle={menuContainerStyle}
        isDisabled={disabled ? true : false}
        simpleValue={simpleValue ? true : false}
        className={'Select-Control'}
        classNamePrefix={'Select-Control-inner'}
        isClearable={true}
      />
      {hasError ? (
        <FormFeedback className='animated fadeIn d-block'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
          {error}
        </FormFeedback>
      ) : (
        ''
      )}
    </FormGroup>
  );
};

export const convertToFloat = value => {
  if (value === '') return value;
  const regex = /^\d*\.?\d*$/;
  if (value.match(regex)) return value;
};

export const convertToNumber = value => {
  if (isNaN(value) || value === '') return value;
  return Number(value);
};
