import { Timeline } from 'antd'
import React, { Component } from 'react'
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';

export default class TimelineDropdown extends Component {
  render() {
    const {options} = this.props

    const currentvalue = ModelPropertiesParser(`{{${this.props.classProperty}}}`, this.props.formValues)
    const currentindextemp = options.findIndex((item)=>{ return item.value === currentvalue })

    const timelinedropdown = options.map((option,index) => {
        return {
         
          value: option.value,
          label: `Stage ${index + 1}`,
          children: option.label,
          color: index <= currentindextemp ? 'green' :'grey'
        };
      })
    return (

            <Timeline mode="left" dotBorderWidth={4}  items={timelinedropdown}/>
    
    )
  }
}
